import React from 'react';
import "../styles/Projects.css";

const Projects = () => {
  return (
    <div className="projects">
      <div className="project">
        <h3>Date Discovery</h3>
        <p>Date Discovery is an application that uses MATLAB's advanced computational capabilities to match you with an ideal partner based on a variety of criterias.</p>
        <a href="https://github.com/makorsha-ai/Date__Discovery.git">GitHub Repository</a>
      </div>
      <div className="project">
        <h3>Snake Game</h3>
        <p>A simple game of Snake where a snake can be controlled to go left, right, up or down. The objective of the game is to collect coins until the snake can no longer fit in the frame.</p>
        
      </div>
    </div>
  );
};

export default Projects;

import React from "react";
import Samii from "../assets/sami.jpg";
import "../styles/About.css";

function About() {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${Samii})` }}
      ></div>
      <div className="aboutBottom">
        <h1> About Me</h1>
        <p>
        I am Syed Sami, a third year student at York University pursuing an Honours Bachelors Degree in Information Technology with a Specialization in Artificial Intelligence. I have a passion for crafting innovative solutions. With a strong foundation in Java Programming and Object-Oriented Programming principles, I have honed my skills in Analytical Programming, JavaScript, and Web Development. Proficient in React, I possess a deep understanding of front-end development and create engaging user interfaces. Equipped with a solid grasp of Algorithms and Discrete Mathematics, I bring a strategic approach to problem-solving and optimization. With a diverse skill set and a keen eye for detail, I consistently deliver high-quality code and collaborate seamlessly with cross-functional teams. My dedication to continuous learning and staying up-to-date with industry trends ensures that they are always ready to tackle new challenges
        </p>
      </div>
    </div>
  );
}

export default About;
import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import '../styles/Footer.css'

function Footer() {
  return (
    <div className="footer"> 
     <div className = "Linkedin">
       <LinkedInIcon /> 
     </div>
     <p> https://www.linkedin.com/in/syed-golam-as-sami-5a38681b6/</p>
    </div>
  )
}

export default Footer
